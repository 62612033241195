<template>
  <div class="container">
    <div class="container_left">
      <div class="case_box">
        <div class="case_box_newChat_btn" @click="getNewChat">
          <img :src="btnIcon" alt=""/>
          <span>创建新会话</span>
        </div>
        <div class="case_box_chatHistory">
          <div class="case_box_chatHistory_divide"></div>
          <span class="case_box_chatHistory_content">历史询问</span>
        </div>
        <div class="case_box_chatHistory_list">
          <div
              class="case_box_chatHistory_item"
              v-for="(item, index) in HistoryLimit.slice(0, 5)"
              :key="index"
              @click="getMessageList(item.sessionBatch)"
          >
            <span>{{ item.modelSearch }}</span>
            <div class="case_box_chatHistory_item_divide"></div>
          </div>
          <div class="case_box_chatHistory_lastItem">保留最近5次对话</div>
        </div>
      </div>
    </div>
    <div class="container_right">
      <div class="chat_box-question--tips">
        <div class="tips_title">
          <div class="tips_title-left">
            <div class="tips_title-left--divide"></div>
            <span>猜你想问</span>
          </div>
          <div class="tips_title-right" @click="reloadTipsBtnList()">
            <img
                :src="reloadBtn"
                alt=""
                @click="refreshTips"
                class="tips_title-right--btn icon-arrow"
            />
            <span @click="refreshTips">换一批</span>
          </div>
        </div>
        <div class="chat_box-question-tips--divide"></div>
        <div class="chat_box-question-tips--item">
          <div
              class="chat_box-question-tips--item-box"
              v-for="(item, index) in processedTipsList"
              :key="index"
              @click="postQuestion(item.dialogueContent)"
          >
            <el-tooltip
                class="item"
                effect="dark"
                :content="item.tooltipContent"
                placement="top"
                :disabled="item.tooltipDisabled"
            >
              <span>
                {{ item.displayText }}
              </span>
            </el-tooltip>
            <img :src="rightIcon" alt=""/>
          </div>
        </div>
      </div>
      <div class="chat_box--body">
        <chat-box
            ref="chatBox"
            :chatMessageList="chatMessageList"
            box-height="450"
            @typing-completed="handleTypingCompleted"
        ></chat-box>
      </div>
      <post-message
          ref="postMessage"
          :show-video="true"
          @update:isDoing="(value) => (isTyping = value)"
          @message-sent="handleMessage"
          :isDoing="isTyping"
          :sessionBatch="sessionBatch"
          :serviceType="serviceType"
      ></post-message>
    </div>
  </div>
</template>
<script>
import chatBox from "@/components/chatBox";
import postMessage from "../../components/postMessage/index.vue";
import {req} from "@/utils/aiChat/request";
import {
  getQuestionListApi,
  getMessageListApi,
  getHistoryListApi,
  saveMessgaeApi,
} from "@/api/com";

export default {
  name: "aiFindWork",
  components: {
    chatBox,
    postMessage,
  },
  data() {
    return {
      userInput: "",
      chatMessageList: [],
      rotFlag: true,
      rotTipFlag: true,
      rightIcon: require("@/assets/icon/aiFindWork/rightIcon.png"),
      addressIcon: require("@/assets/icon/aiFindWork/addressTips.png"),
      btnIcon: require("@/assets/icon/aiFindWork/add_btn_icon.png"),
      reloadBtn: require("@/assets/icon/aiFindWork/reload_btn.png"),
      questionHistoryList: [
        // '电话面试需要注意的问题',
        // '电话面试需要注意的问题',
        // '电话面试需要注意的问题',
        // '电话面试需要注意的问题',
        // '电话面试需要注意的问题'
      ],
      questionTipsList: [
        // '帮我拟一份科技公司管理制度',
        // '帮我拟一份科技公司管理制度',
        // '帮我拟一份科技公司管理制度',
        // '帮我拟一份科技公司管理制度',
      ],
      isTyping: false,
      history: [],
      pageNum: 0,
      historyPageNum: 1,
      sessionBatch: null, //会话批次,用来判断是新会话,还是旧会话,如果是新会话就不用传
      isNewSession: "0", //是否新会话（0-新会话，1-历史会话）
      serviceType: "",
      queryContent: "",
      total:0
    };
  },
  computed: {
    HistoryLimit() {
      return this.questionHistoryList.map((item) => {
        const maxLength = 14; // 设置最大长度
        if (item.modelSearch.length > maxLength) {
          return {
            ...item,
            modelSearch: item.modelSearch.substring(0, maxLength) + "...",
          };
        }
        return item;
      });
    },
    processedTipsList() {
      return this.questionTipsList.map((item) => {
        const processedText = this.truncateText(item.dialogueContent, 16);
        return {
          ...item,
          displayText: processedText.text,
          tooltipContent: processedText.tooltip ? item.dialogueContent : "",
          tooltipDisabled: !processedText.tooltip, // 禁用工具提示，除非文本被截断
        };
      });
    },
  },
  mounted() {
    // this.$showLoginModal(true)

    window.addEventListener('load', () => {
      const currentRoute = this.$router.currentRoute;
      if (currentRoute.query && Object.keys(currentRoute.query).length > 0) {
        this.$router.push({
          path: currentRoute.path, query: {
            serviceType: 3
          }
        });
      }
    })
  },
  created() {
    if (this.$route.query.dialogueContent) {
      this.queryContent = this.$route.query.dialogueContent;
      this.postQuestion(this.queryContent);
    }
    // 检查路由查询参数中是否有 serviceType
    if (this.$route.query.serviceType) {
      this.serviceType = this.$route.query.serviceType;
    }
    this.getQuestionList();
    this.getHistoryList();
  },
  methods: {
    //创建新对话
    getNewChat() {
      this.getHistoryList();
      this.$refs.postMessage.clearPlay()
      this.isNewSession = "0";
      this.chatMessageList = [];
      this.sessionBatch = null;
    },
    //限制字数，用...替代
    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return {text: text.substring(0, maxLength) + "...", tooltip: true};
      }
      return {text: text, tooltip: false};
    },
    //四个问题列表换一批
    refreshTips() {
      this.getQuestionList();
    },
    // serviceType : 1-AI招工，2-AI找活，3-人力资源问答助手，4-人力资源法律助手，5-智能面试
    getQuestionList() {
      this.pageNum += 1;

      const data = {
        pageNum: this.pageNum,
        pageSize: 4,
        serviceType: "3",
      };

      if (this.pageNum === 1) {
        getQuestionListApi(data)
            .then((res) => {
              this.total = res.data.total;
              this.questionTipsList = res.data.rows;
            })
            .catch((error) => {
              console.error("错误信息:", error);
            });
      } else if (this.pageNum > 1 && this.total % this.pageNum*4 === 0) {
        getQuestionListApi(data)
            .then((res) => {
              this.questionTipsList = res.data.rows;
            })
            .catch((error) => {
              console.error("错误信息:", error);
            });
      } else if (this.total % this.pageNum*4 !== 0) {
        console.log('1111')
        this.pageNum = 0;
        getQuestionListApi(data)
            .then((res) => {
              this.questionTipsList = res.data.rows;
            })
            .catch((error) => {
              console.error("错误信息:", error);
            });
      }
    },
    transformMessages(historyMessages) {
      let messageId = 1;
      historyMessages.forEach((message) => {
        // 处理用户消息
        if (message.modelSearch) {
          this.chatMessageList.push({
            id: messageId++, //分配ID递增
            type: "text",
            user: "user",
            content: message.modelSearch,
          });
        }

        // 处理机器人消息
        if (message.modelRecover) {
          this.chatMessageList.push({
            id: messageId++,
            type: "text",
            user: "robot",
            content: message.modelRecover,
            total: message.modelRecoverNum || 0, // 添加total字段，如果有的话
          });
        }
      });

      return this.chatMessageList;
    },
    // 点击历史记录标题，获取历史消息
    getMessageList(sessionBatch) {
      this.isTyping = false;
      this.chatMessageList = [];
      // console.log(sessionBatch);
      this.sessionBatch = sessionBatch; //存储批次,发送消息需要带
      this.isNewSession = "1"; //设置成历史会话

      const data = {
        sessionBatch: sessionBatch,
        serviceType: "3",
      };
      getMessageListApi(data)
          .then((res) => {
            // console.log(res);
            console.log("获取历史右侧消息记录:", res);
            const messagedataList = this.transformMessages(res.data);
            this.$refs.chatBox.updateIsLoading();

            this.$refs.chatBox.scrollToBottom();
          })
          .catch((error) => {
            console.error("错误信息:", error);
          });
    },
    //负责对接ai打字机是否完成了,改变isTyping的状态 false-打字已完成  true-正在打字
    handleTypingCompleted() {
      this.isTyping = false;
    },
    postQuestion(question) {
      if (this.isTyping) return

      const messageData = {
        message: question,
      };
      this.handleMessage(messageData);
    },
    lookMessages() {
      // console.log(this.chatMessageList);
    },
    //接收<post-message>组件用户发送的消息
    handleMessage(messageData) {
      // localStorage.getItem('sensitiveWords')与message核验，如果有敏感词，则return 并提示有敏感词，并且做处理
      const sensitiveWords = JSON.parse(
          localStorage.getItem("sensitiveWords") || "[]"
      );
      let sensitiveMessage = messageData.message;
      let foundSensitiveWord = false;

      sensitiveWords.forEach((word) => {
        // 使用正则表达式确保匹配完整单词，避免部分匹配
        // const regex = new RegExp(`\\b${word}\\b`, "gi");
        // if (regex.test(sensitiveMessage)) {
        //   foundSensitiveWord = true;
        //   sensitiveMessage = sensitiveMessage.replace(regex, "***");
        // }


        const regex = new RegExp(`\\b${word}\\b`, "gi");
        let a = sensitiveMessage.indexOf(word);
        // 如果匹配到敏感词，则替换为***
        if (a>=0) {
          foundSensitiveWord = true;
          sensitiveMessage = sensitiveMessage.replace(regex, "***");
        }

      });

      if (foundSensitiveWord) {
        this.isTyping = false;
        this.$message.error("您的消息包含敏感词，请重新编辑后发送。");
        return;
      }

      this.isTyping = true;
      // 动态分配新消息的 ID
      const newId = this.chatMessageList.length + 1;
      const userMessage = {
        id: newId,
        type: "text",
        user: "user",
        content: messageData.message,
      };
      // 将新消息添加到 chatMessageList
      this.chatMessageList.push(userMessage);
      // 强制 Vue 立即更新 DOM
      this.$nextTick(() => {
        this.safeScrollBottom();
      });
      // 添加空的robot消息作为加载占位符
      const loadingMessage = {
        id: this.getNewId(),
        type: "text",
        user: "robot",
        content: "", // 空内容
        isLoading: true,
      };
      this.chatMessageList.push(loadingMessage);
      // 强制 Vue 立即更新 DOM
      this.$nextTick(() => {
        this.safeScrollBottom();
      });

      this.sendToAIAndGetReply(messageData.message, messageData.sessionBatch);
    },
    safeScrollBottom() {
      if (
          this.$refs.chatBox &&
          typeof this.$refs.chatBox.scrollToBottom === "function"
      ) {
        this.$refs.chatBox.scrollToBottom();
      }
    },
    // 获取历史消息记录
    getHistoryList() {
      this.historyPageNum = 1;
      const data = {
        pageNum: 1,
        pageSize: 5,
        serviceType: "3",
      };
      getHistoryListApi(data)
          .then((res) => {
            this.questionHistoryList = res.data.rows;
            console.log(this.questionHistoryList);
          })
          .catch((error) => {
            console.error("错误信息:", error);
          });
    },
    //保存消息数据到后端
    saveHistory(saveData) {
      // console.log("1312131", saveData);
      const data = {
        serviceType: "3",
        fileType: "0",
        modelSearch: saveData.userMessageContent,
        modelRecover: saveData.replyContent,
        modelType: "1",
        modelRecoverNum: saveData.length,
      };
      // 如果 sessionBatch 有值，则添加到请求数据中
      if (this.sessionBatch) {
        data.sessionBatch = this.sessionBatch;
      }
      saveMessgaeApi(data)
        .then((res) => {
          console.log("响应:", res);
          if (this.sessionBatch == null && res.data.sessionBatch) {
            this.sessionBatch = res.data.sessionBatch;
          }
        })
        .catch((error) => {
          console.error("错误信息:", error);
        });
    },
    // 发送消息到AI并获取回复
    sendToAIAndGetReply(userMessageContent) {
      this.getAIReply(userMessageContent).then((replyContent) => {
        // 找到最后一个isLoading的robot消息，并更新它
        const lastLoadingMessageIndex = this.chatMessageList.findIndex(
            (m) => m.user === "robot" && m.isLoading
        );
        if (lastLoadingMessageIndex !== -1) {
          const updatedMessage = {
            ...this.chatMessageList[lastLoadingMessageIndex],
            content: "",
            isLoading: false, // 更新为非加载状态
            total: replyContent.length, // 计算AI回复的字数
            displayContent: "", // 初始化displayContent为空
          };
          const saveData = {
            userMessageContent: userMessageContent,
            replyContent: replyContent,
            length: replyContent.length,
          };

          this.saveHistory(saveData);

          // console.log("现在的消息列表", this.chatMessageList);

          // 消息打字机效果
          req("post", "/qps/tools/text2audio",
              {text: this.removeNewLine(replyContent)},
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }).then(iii => {

            this.$set(
                this.chatMessageList,
                lastLoadingMessageIndex,
                updatedMessage
            );
            let wavBlob = this.base64ToBlob(iii.data, "audio/wav");
            let audioUrl = this.blobToUrl(wavBlob);
            this.$refs.postMessage.startPlay(audioUrl)
            this.$refs.chatBox.animateMessage(updatedMessage, replyContent);
          }).catch(() => {
            this.$set(
                this.chatMessageList,
                lastLoadingMessageIndex,
                updatedMessage
            );
            this.$refs.chatBox.animateMessage(updatedMessage, replyContent);
          })
        }
        this.$nextTick(() => {
          this.safeScrollBottom();
        });
      });
    },
    base64ToBlob(base64String, type) {
      const decodedData = window.atob(base64String);
      const arrayBuffer = new ArrayBuffer(decodedData.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < decodedData.length; i++) {
        uint8Array[i] = decodedData.charCodeAt(i);
      }
      return new Blob([uint8Array], {type});
    },
    blobToUrl(blob) {
      return URL.createObjectURL(blob);
    },
    // 去除换行符
    removeNewLine(text) {
      if (!text) return '';
      if (!/\*/g.test(text)) return text;
      return text.replace(/\*/g, "").trim();
    },
    // AI回复
    async getAIReply(userMessageContent) {
      try {
        const query = userMessageContent;
        const data = {
          query,
          history:
              this.history.length > 5 ? this.history.slice(-5) : this.history,
        };
        const res = await req("post", "/qus/common", data);
        // console.log("这是ai大模型", res.data);
        this.history.push([query, res.data]);
        return res.data;
      } catch (error) {
        console.error("请求AI回复出错:", error);
        // 处理错误或返回错误信息
        return "抱歉，无法获取AI的回复。";
      }
    },
    getNewId() {
      return this.chatMessageList.length + 1;
    },
    reloadBtnList() {
      let element = document.querySelector(".case_box-work-title--right_btn");
      if (this.rotFlag) {
        element.classList.add("icon-arrow-rotate");
      } else {
        element.classList.remove("icon-arrow-rotate");
      }
      this.rotFlag = !this.rotFlag;
    },
    reloadTipsBtnList() {
      let element = document.querySelector(".tips_title-right--btn");
      if (this.rotTipFlag) {
        element.classList.add("icon-arrow-rotate");
      } else {
        element.classList.remove("icon-arrow-rotate");
      }
      this.rotTipFlag = !this.rotTipFlag;
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 1840px;
  display: flex;
  margin-top: 27px;

  .container_left {
    width: 360px;

    .case_box {
      width: 360px;
      /* height: 450px; */
      border-radius: 12px;
      margin-bottom: 20px;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;

      .case_box_newChat_btn {
        width: 308px;
        height: 44px;
        cursor: pointer;
        margin-top: 47px;
        background: linear-gradient(113deg, #60a4ff 0%, #1957ff 100%);
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 16px;
          height: 16px;
        }

        span {
          margin-left: 3px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #ffffff;
        }
      }

      .case_box_newChat_btn:hover {
        background: linear-gradient(293deg, #60a4ff 0%, #1957ff 100%);
      }

      .case_box_chatHistory_list {
        width: 308px;
        background: #f1f5ff;
        border-radius: 6px;
        margin-bottom: 13px;

        .case_box_chatHistory_item {
          height: 51px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          margin-left: 11px;
          margin-right: 11px;
          font-size: 14px;
          color: #333333;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-end;
          cursor: pointer;

          span {
            margin-left: 20px;
            margin-top: 13px;
            margin-bottom: 13px;
          }

          .case_box_chatHistory_item_divide {
            width: 286px;
            height: 1px;
            background-color: #979797;
            opacity: 0.22;
          }
        }

        .case_box_chatHistory_lastItem {
          width: 100%;
          margin-top: 12px;
          margin-bottom: 16px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 10px;
          color: #8d8d8d;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .case_box-work-title {
        width: 308px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .case_box-work-title--left {
          display: flex;
          align-items: center;
          margin-top: 18px;

          .case_box-work-title--left_divide {
            width: 3px;
            height: 15px;
            background: #3076ff;
          }

          span {
            margin-left: 5px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 18px;
            color: #333333;
            font-style: normal;
          }
        }

        .case_box-work-title--right {
          margin-top: 20px;
          display: flex;
          align-items: center;
          cursor: pointer;

          img {
            width: 12px;
            height: 12px;
            margin-right: 4px;
          }

          span {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
            font-style: normal;
          }
        }
      }

      .case_box-work-list {
        width: 320px;
        margin-top: 16px;

        .case_box-work-list--item {
          width: 320px;
          height: 70px;
          display: flex;
          margin-bottom: 13px;
          justify-content: space-between;
          border-bottom: 1px solid #dde0e8;

          .case_box-work-list--item-left {
            .work-title {
              font-family: PingFangSC, PingFang SC;
              font-weight: bold;
              font-size: 14px;
              color: #555555;
            }

            .work-tag-box {
              display: flex;
              margin-top: 4px;

              .work-tag {
                margin-right: 3px;
                color: #ffffff;
                border-radius: 1px;
                padding: 2px 7px;
                background-color: #52c4a4;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 9px;
              }
            }

            .work-address {
              margin-top: 5px;

              img {
                width: 6px;
                height: 8px;
              }

              span {
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 10px;
                color: #888888;
              }
            }
          }

          .case_box-work-list--item-right {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: space-between;

            .salary_box {
              margin-top: 2px;

              .salary_box-num {
                font-family: DINAlternate, DINAlternate;
                font-weight: bold;
                font-size: 14px;
                color: #555555;
              }

              .salary_box-unit {
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 8px;
                color: #555555;
              }
            }

            .record_btn {
              margin-bottom: 11px;
              display: flex;
              cursor: pointer;
              align-items: center;
              justify-content: center;
              width: 61px;
              height: 22px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 10px;
              color: #3076ff;
              border-radius: 11px;
              border: 1px solid #3076ff;
            }
          }
        }
      }
    }

    .case_box_chatHistory {
      width: 308px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      margin-top: 40px;
      margin-bottom: 14px;
      font-size: 18px;
      color: #333333;
      display: flex;
      align-items: center;

      .case_box_chatHistory_divide {
        width: 3px;
        height: 15px;
        background: #3076ff;
        margin-right: 5px;
      }
    }
  }

  .container_right {
    width: 1440px;
    margin-bottom: 55px;
    margin-left: 26px;

    .chat_box-question--tips {
      width: 1440px;
      height: 166px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #ffffff;
      border-radius: 12px;

      .tips_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 54px);
        margin-top: 20px;
        margin-left: 24px;
        margin-right: 30px;

        .tips_title-left {
          display: flex;
          align-items: center;

          .tips_title-left--divide {
            width: 3px;
            height: 15px;
            margin-right: 5px;
            background: #3076ff;
          }

          span {
            font-family: PingFangSC, PingFang SC;
            font-weight: bold;
            font-size: 18px;
            color: #333333;
          }
        }

        .tips_title-right {
          display: flex;
          align-items: center;
          cursor: pointer;

          img {
            width: 19px;
            height: 16px;
          }

          span {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 17px;
            color: #333333;
          }
        }
      }

      .chat_box-question-tips--divide {
        width: 1386px;
        height: 1px;
        background-color: #979797;
        /* border: 1px solid #979797; */
        opacity: 0.2;
        margin-top: 16px;
      }

      .chat_box-question-tips--item {
        width: calc(100% - 54px);
        display: flex;
        justify-content: space-between;

        .chat_box-question-tips--item-box {
          width: 328px;
          height: 60px;
          background: #f1f5ff;
          border-radius: 8px;
          margin-top: 21px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          span {
            font-family: PingFangSC, PingFang SC;
            font-weight: bold;
            font-size: 16px;
            color: #333333;
          }

          img {
            width: 12px;
            height: 10px;
            margin-left: 4px;
          }
        }
      }
    }

    .chat_box--body {
      width: 100%;
      margin-top: 36px;
    }
  }
}

.icon-arrow {
  transition: all 0.3s ease-out;
}

.icon-arrow-rotate {
  transform: rotate(360deg);
}
</style>
