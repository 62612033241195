<template>
    <div class="resumeTemplate-container">
        <div class="container" id="resumeTemplateLeft">
            <div class="templateBox">
                <div class="template1">
                    <div class="template1-header">
                        <div class="avater">
                            <div class="inside-border">
                                <!-- <el-image v-if="showData && showData.avatar" :src="showData.avatar">
                                </el-image> -->
                                <img v-if="showData && showData.avatar" :src="showData.avatar">
                            </div>
                        </div>
                        <div class="right-info">
                            <div>
                                <span class="name">{{ showData.userName }}</span>
                                <span class="" v-if="showData.sex">{{ showData.sexName}}</span>
                                <span class="mr8 ml8">|</span>
                                <span>{{ showData.workTypesName }}</span>
                            </div>

                            <div class="adressBox">
                                <div class="info mt20">
                                    <img src="@/assets/resumeTemplateDownload/adreess.png" alt="">
                                    {{ showData.workPlaceWant }}
                                </div>
                                <div class="info mt8">
                                    <img src="@/assets/resumeTemplateDownload/phone.png" alt="">
                                    {{ showData.mobile }}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="template1-main">
                        <div class="base-information">
                            <div class="info-wrap">
                                <titleBar name="基本信息" />
                                <div class="info">
                                    <p class="info-item">
                                        <span>出生年月：</span>
                                        <span>{{ showData.birthDate }}</span>
                                    </p>
                                    <p class="info-item" v-if="showData.eduLevel">
                                        <span>文化程度：</span>
                                        <span>{{ showData.eduLevelName }}</span>
                                    </p>
                                    <p class="info-item">
                                        <span>毕业学校：</span>
                                        <span>{{ showData.graduate }}</span>
                                    </p>
                                    <p class="info-item" v-if="showData.skill">
                                        <span>技能：</span>
                                        <span>{{ showData.skill }}</span>
                                    </p>
                                    <p class="info-item">
                                        <span>技能证书：</span>
                                        <span v-if="showData.certNames">{{ showData.certNames }}<span v-if="showData.certNames">-</span> {{ showData.certSecNames }}</span>
                                        <span v-else>暂无</span>
                                    </p>
                                </div>
                            </div>
                            <div class="info-wrap">
                                <titleBar name="求职意向" />
                                <div class="info">
                                    <p class="info-item">
                                        <span>期望岗位：</span>
                                        <span v-if="showData.typeName">{{ showData.typeName + '/' + showData.workTypesName }}</span>
                                        <span v-else>暂无</span>
                                    </p>
                                    <p class="info-item">
                                        <span>期望薪资：</span>
                                        <span
                                            v-if="showData.minWorkSalarys && showData.minWorkSalarys != -1 && showData.maxWorkSalarys != -1">{{
                                                showData.minWorkSalarys + '-' + showData.maxWorkSalarys }} {{ showData.workSalarysUnitName }}</span>
                                        <span v-else>面议</span>
                                    </p>
                                    <p class="info-item">
                                        <span>期望工作地：</span>
                                        <span>{{ showData.workPlaceWant }}</span>
                                    </p>
                                    <p class="info-item"
                                        v-if="showData.csUserJobWantExtend && showData.csUserJobWantExtend.otherBenefitsRequired">
                                        <span>其他要求：</span>
                                        <span>{{ showData.csUserJobWantExtend.otherBenefitsRequired }}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="work-experience mt18"
                            v-if="showData.csJobExperiences && showData.csJobExperiences.length > 0">
                            <titleBar name="工作经历" />
                            <div class="info" v-for="(item, index) in showData.csJobExperiences" :key="index">
                                <div class="info-item">
                                    <div class="name">
                                        <span>{{ item.entryTime }}<span style="margin: 0 8px;">至</span> {{ item.exitTime
                                            }} </span>
                                        <span>{{ item.workplace }}</span>
                                        <span>{{ item.job }}</span>
                                    </div>
                                    <div class="content">
                                        {{ item.jobContent }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="self-introduction mt18">
                            <titleBar name="自我介绍" />
                            <div class="info">
                                {{ showData.descr }}
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 模板二 -->
                <!-- <div class="template2"></div> -->
            </div>
        </div>
        <el-button class="downLoading-btn" @click="exportPic"><i class="el-icon-download"></i> 点击下载简历模板一</el-button>
    </div>
</template>

<script>
import html2Canvas from 'html2canvas';
import titleBar from './titleBar.vue'
import moment from 'moment';
import {dictTypeApi} from "@/api/aiFindWork";
export default {
    name: 'resumeTemplateLeft',
    components: {
        titleBar
    },
    data() {
        return {
            showData: {}
        };
    },

    methods: {
        getInfo(showData) {
          console.log("showData",showData)
            this.showData = showData;
          this.getDictList(showData);
            this.showData.birthDate = this.showData.birthDate ? moment(this.showData.birthDate).format("YYYY-MM-DD") : '';
        },
        // 导出简历
        exportPic() {
            this.downloading = true
            html2Canvas(document.querySelector("#resumeTemplateLeft"), {
                allowTaint: true,  //开启跨域
                useCORS: true,
            }).then(canvas => {
                let dataURL = canvas.toDataURL("image/png");
                if (dataURL !== "") {
                    this.downloading = false
                    var a = document.createElement('a')
                    a.download = "个人简历模板一";
                    a.href = dataURL;
                    a.click()
                }
            }).catch(() => {
                this.downloading = false
            })
        },
      // 根据字典获取性别label
        getDictList(showData){
          dictTypeApi({dictType:'sys_user_sex'}).then(res => {
            if (res && res.data instanceof Array) {
              let matchDic = res.data.find(item=>item.dictValue ===showData?.sex);
              if (matchDic) {
                this.$set(this.showData,"sexName",matchDic?.dictLabel)
               this.$forceUpdate()
              }
            }
          })
        }
    }
}
</script>
<style lang='scss' scoped>
.resumeTemplate-container {
    position: relative;
    width: 100%;
    background: #fff;
    .downLoading-btn {
        position: absolute;
        // bottom: 0;
        margin-top: 50px;
        left: 50%;
        transform: translateX(-50%);
        color: #333;
        font-size: 18px;
    }
}
::v-deep.container {
    display: flex;
    height: 100%;
    // 简历 弹窗
    .templateBox {
        width: 100%;
        height: 100%;
        .mt20 {
            margin-top: 20px;
        }

        .mt8 {
            margin-top: 8px;
        }

        .ml {
            margin-right: 8px;
        }

        .mr {
            margin-left: 8px;
        }

        .mt18 {
            margin-top: 18px;
        }

        .mt15 {
            margin-top: 15px;
        }

        .template1 {
            &-header {
                height: 204px;
                background: url('~@/assets/resumeTemplateDownload/template1-header.png');
                box-sizing: border-box;
                padding: 40px;

                display: flex;

                .avater {
                    border: 1px solid #FFFFFF;
                    width: 126px;
                    height: 126px;
                    border-radius: 50%;

                    .inside-border {
                        border: 4px solid #3076FF;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        box-sizing: border-box;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        z-index: 111;
                    }
                }

                .right-info {
                    flex: 1;
                    margin-left: 36px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 500;
                    font-size: 20px;
                    color: #fff;

                    .ml8 {
                        margin-left: 8px;
                    }

                    .mr8 {
                        margin-right: 8px;
                    }

                    .name {
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 500;
                        font-size: 36px;
                        margin-right: 10px;
                    }

                    .adressBox {
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 500;
                        font-size: 18px;
                        color: #FFFFFF;

                        .info {
                            display: flex;
                            align-items: center;

                            img {
                                width: 20px;
                                height: 20px;
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }

            &-main {
                background: #fff;
                height: max-content;
                padding: 22px 40px 20px 36px;

                .base-information {
                    // 基本信息
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-column-gap: 48px;

                    .info-wrap {
                        .info {
                            &-item {
                                margin-bottom: 10px;

                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }

                .info {
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    font-size: 12px;
                    color: #333333;

                    display: flex;
                    flex-direction: column;
                    margin-top: 15px;
                }

                .work-experience {

                    // 工作经历
                    // margin-top: 18px;
                    .info {
                        .name {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            font-family: PingFangSC, PingFang SC;
                            font-weight: 500;
                            font-size: 12px;
                            color: #1E356D;
                        }

                        .content {
                            margin-top: 10px;
                            font-family: PingFangSC, PingFang SC;
                            font-weight: 400;
                            font-size: 12px;
                            color: #333333;
                        }
                    }
                }
            }
        }

        .template2 {
            border: 1px solid green;
        }
    }
}
</style>
