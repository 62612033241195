<template>
    <el-dialog
        :visible.sync="dialogVisible"
        width="70%"
        class="resumeTemplateDialog">
        <div class="resumeTemp-wrap">
            <resumeTemplateLeft ref="resumeTemplateLeft"/>
            <resumeTemplateRight ref="resumeTemplateRight"/>  
        </div>
    </el-dialog>
</template>

<script>
import * as $api from "@/api/personalCenter";
import {getResumeApi} from "@/api/aiInterView";
import moment from 'moment'
import resumeTemplateLeft from './resumeTemplateLeft.vue'
import resumeTemplateRight from './resumeTemplateRight.vue'
export default {
    name: 'ResumeTemplateDownload',
    components: {
        resumeTemplateLeft,
        resumeTemplateRight
    },
    data() {
        return {
            showData: { },
            // 辅助数据, 用于辅助数据展示
            dicData: {},
            loading: false,
            dialogVisible: false,  
            downloading: false,   // 简历下载按钮
            dicDataArr: [],    // 所有字典数据
            
        };
    },
    methods: {
        async getDetail(item, name) {
            // 加载初始化相关数据
            try {
                this.loading = true
                await this.getDataFromDictType();
            } catch (e) {
                console.error("获取数据失败", e);
                this.loading = false;
            }
            // { id: item.userJonWantId }
            getResumeApi().then(res => {   // 判断是否有简历  有才能开始面试
                let { code, msg, data } = res
                if (code == 200) {
                    this.dialogVisible = true
                    this.showData = data;
                    if (data.fileUploadpath && !name &&!name == '简历模板') {   //  存在智联简历
                        window.open(data.fileUploadpath)
                        return
                    }
                    this.showData.csJobExperiences && this.showData.csJobExperiences.forEach(item => {
                        item.entryTime = moment(item.entryTime).format("YYYY-MM-DD");
                        item.exitTime = moment(item.exitTime).format("YYYY-MM-DD");
                    });
                    this.dicDataArr.forEach(item =>{
                        if (item.dictType == 'dispatch_educational' && this.showData.eduLevel == item.dictValue) {
                            this.showData.eduLevelName = item.dictLabel
                        }
                        if (item.dictType == 'crm_pro_unit' && this.showData.workSalarysUnit == item.dictValue) {
                            this.showData.workSalarysUnitName = item.dictLabel
                        }
                        if (item.dictType == 'sys_user_sex' && this.showData.proUnit == item.dictValue) {
                            this.showData.sexName = item.dictLabel
                        }
                    })
                    this.$nextTick(async () => {
                        try {
                          this.showData.avatar = await this.imageUrlToBase64(this.showData.avatar || require("@/assets/icon/home/avatar.png"))
                        } catch(e) {
                          console.error(e)
                        }
                        this.$refs.resumeTemplateLeft.getInfo( this.showData)
                        this.$refs.resumeTemplateRight.getInfo( this.showData)
                    })
              }

            }).catch(error => {
                // this.$refs.resumeTipDialog.showDialog()
            })
        },
      // 根据字典值获取数据，包括 性别列表、文化程度列表、薪水tab列表
      async getDataFromDictType() {
            const fields = [
                "sys_user_sex", // 性别列表
                "dispatch_educational", // 文化程度列表
                "crm_pro_unit", // 薪水tab列表
            ];
          const res = await $api.dictTypeAPI({ dictType: fields.join(",") });
            this.dicDataArr  = res.data
            this.loading = false
            // 数据分类
            fields.forEach(item => {
                this.dicData[item] = res.data.filter(v => v.dictType === item)
            })
        },
        // 字典 转文字
        fitlersType(type, value) {
            let obj = this.dicData[type].find(item => item.dictValue == value)
            return obj ? obj.dictLabel : ''
        },
        async imageUrlToBase64(url) {
          try {
            // 使用fetch API获取图片资源
            const response = await fetch(url);
            // 检查是否成功获取资源
            if (!response.ok) {
              throw new Error(`图片加载失败，状态码：${response.status}`);
            }
            // 将图片数据转换为Blob
            const blob = await response.blob();
            const promise = new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = () => resolve(reader.result);
              reader.onerror = reject;
              reader.readAsDataURL(blob);
            });
            return await promise
          } catch (error) {
            throw new Error('转换过程中发生错误:' + error);
          }
        }

    }
}
</script>
<style lang='scss' scoped>
p {
    padding: 0;
    margin: 0;
}
::v-deep.resumeTemplateDialog {
    margin-bottom: 50px;
    .el-dialog {
        background: transparent!important;
    }
    .resumeTemp-wrap {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-column-gap: 50px;
        // background-color: rgba(0,0,0,0.1);
        background: transparent;
    }

    .downLoading-btn {
        border-radius: 4px;
        border: 1px solid #BEBEBE;
    }
    .el-dialog__header {
        padding: 0!important;
    }
    .el-dialog__body {
        padding: 0!important;
        background: transparent!important;
    }
    .el-dialog__headerbtn {
        .el-dialog__close {
            color: #fff;
            font-size: 20px;
        }
    }
    .dialog-footer {
        position: absolute;
        top: 0;
        right: -150px;
        // left: 50%;
        // transform: translateX(-50%);
    }

}

</style>
