<template>
    <div class="bg" v-loading.fullscreen.lock="fullscreenLoading">
        <div class="mb40">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">总览</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: breadcrumb.path }">{{ breadcrumb.name }}</el-breadcrumb-item>
                <el-breadcrumb-item>岗位详情</el-breadcrumb-item>
            </el-breadcrumb>

            <div class="detail-container">
                <!-- 最顶部 基础信息 工作 多少钱一天等信息 -->
                <div class="top-row" v-if="list">
                    <div class="left-info">
                        <div class="flexCenter">
                            <div class="tag" v-if="!!list.postTypeName">{{ list.postTypeName }}</div>
                            <el-tooltip class="item" effect="dark" placement="top-end" v-if="!!list.workTypeName">
                                <div slot="content">{{ list.workTypeName }}</div>
                                <div class="name singeLine" style="max-width: 480px;">{{ list.workTypeName }}</div>
                            </el-tooltip>
                            <!-- <div class="name" v-if="!!list.workTypeName">{{ list.workTypeName }}</div> -->
                            <div class="money" v-if="list.minProSpend == -1">面议</div>
                            <div class="money" v-if="!!list.minProSpend && list.minProSpend != -1">{{ list.minProSpend + '~' + list.maxProSpend }} {{ list.proUnitName }}</div>
                        </div>
                        <div class="info">
                            <span><i class="el-icon-s-custom"></i> 人数 ：{{ list.employeesNum }}人</span>
                            <span><i class="el-icon-view"></i> 浏览：{{ list.browsedNum }}次</span>
                            <span><i class="el-icon-location"></i> {{ list.proArea }}</span>
                            <span><i class="el-icon-time"></i> {{ list.createTime }}</span>
                        </div>
                    </div>

                    <!-- 右侧按钮 -->
                    <!-- 删除标志delFlag（0-存在，2-删除） -->
                    <div class="deleteBtn" v-if="identity == 2 && (userInfo.phonenumber == list.contactMobile ) && list.delFlag !=2">
                        <span @click="deleteFn" >删除</span>
                        <span v-if="list.isDown != 1" class="edit" @click="publishDialog"> 修改编辑</span>
                        <span v-if="list.isDown == 1" class="edit">岗位已下架</span>
                    </div>
                    <!-- "isCurrentUserInterview": "0",	//当前用户是否已面试（0-否，1-是）
                    "isCurrentUserRegister": "0",	//当前用户是否已报名（0-否，1-是） -->
                    <!-- 工人  ai找活详情 -->
                    <div class="deleteBtn" v-if="identity == 1 &&Object.hasOwn(list, 'delFlag') && list.delFlag != 2">
                        <span class="btnbe disableBtn" v-if="list.isCurrentUserRegister == 1">已报名</span>
                        <span class="btn6ff" v-if="list.isCurrentUserRegister == 0 && list.isDown != 1" @click="goToRegistration">报名登记</span>
                        <span class="btnBg6ff" @click="goConnection">联系老板</span>
                        <span class="btnbe disableBtn" v-if="list.isDown == 1">岗位已下架</span>
                    </div>
                    <div class="deleteBtn" v-if="list.delFlag == 2">
                        <span class="btnbe disableBtn" >岗位已取消</span>
                    </div>
                  <div class="deleteBtn" v-if="!Object.hasOwn(list, 'delFlag')">
                        <span class="btnbe disableBtn" >岗位不存在</span>
                    </div>
                </div>

                <div class="line"></div>
                <!-- hr 详情-->
                <div class="flexBetween personNumber" v-if="identity == 2 && (userInfo.phonenumber == list.contactMobile )" >
                    <span>已报名人数：{{ list.jobWantApplyList ? list.jobWantApplyList.length : 0 }}</span>
                    <span class="btn" v-if="list.delFlag != 2" @click="GomyPositionList">查看报名列表</span>
                </div>

                <div class="detail">
                    <!-- 工人  ai找活详情 -->
                    <div class="flexBetween" v-if="identity == 1">
                        <div class="tag">
                            <span>{{ list.postTypeName }}</span>
                            <span>{{ list.employeesNum }}人</span>
                        </div>
                        <div class="btnBox" v-if="list.delFlag !=2">
                            <span class="btn" v-if="list.isCurrentUserInterview == 0 && list.realStatus == 1 && list.isDown != 1" @click="goToReview">开始面试</span>
                            <!-- v-if="list.isCurrentUserInterview == 1" -->
                            <span class="btn btn33" v-if="list.isCurrentUserInterview == 1"  @click="reviewPlay">面试回放</span>
<!--                            <span class="btn btn33" v-if="list.isCurrentUserInterview != 1 && list.realStatus != 1">岗位已下架</span>-->
                            <span class="btn btn33" v-if="list.realStatus != 1 && list.isDown == 0">岗位已下架</span>
                        </div>
                    </div>

                    <div class="mb15" v-if="!!list.proCompanyName">
                        <div class="name">公司名称：</div>
                        <div class="info">{{ list.proCompanyName }}</div>
                    </div>
                  <div class="mb15" v-if="false">
                        <div class="name">岗位职责：</div>
                        <div class="info">{{ list.descr }}</div>
                    </div>
                    <div class="mb15" v-if="list.proDesc">
                        <div class="name">工作内容：</div>
                        <div class="info">
                          <div v-for="(item, index) in textToListByBreak(list.proDesc)" :key="index">
                            {{ item }}
                          </div>
                        </div>
                    </div>
                    <div class="mb15">
                        <div class="name">工作地址：</div>
                        <div class="map-wrap">
                            <div class="adress"><i class="el-icon-location"></i> {{ list.proArea }}</div>
                            <div class="map"  v-show="!isDialogPublish">
                                <el-amap
                                    ref="centerMap"
                                    class="amap-box"
                                    :vid="'amapVue'"
                                    :zoom="mapConfig.zoom"
                                    :zooms="mapConfig.zooms"
                                    :center="mapConfig.center"
                                    :plugin="plugin">
                                    <!-- <el-amap-marker
                                        v-for="(marker, index) in markers"
                                        :position="marker.position"
                                        :key="index"
                                    ></el-amap-marker> -->
                                </el-amap>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- 去联系 -->
        <scanCodePay ref="scanCodePay"/>
        <callDialog ref="callDialog" />
        <!-- 发布岗位 -->
        <commonForm ref="commonForm" @flashData="flashData"/>

        <!-- 删除确认弹窗 -->
        <deleteDialog ref="deleteDialog"/>
        <!-- 简历填写提示 -->
        <resumeTipDialog ref="resumeTipDialog"/>
    </div>
</template>
<script>
import * as $api from '@/api/personalCenter'
import { parseTime } from '@/utils/date'
import commonForm from './components/commonForm.vue';
import deleteDialog from './components/jobDetails/deleteDialog.vue';
import { mapGetters } from 'vuex'
import store from "@/store";
export default {
    components: {
        // centerCon,
        commonForm,
        deleteDialog
    },
    data() {
        return {
            hrImg: require('@/assets/intelligentInterview/hr.png'),
            workImg: require('@/assets/intelligentInterview/work.png'),
            // identity: '',
            breadcrumb: {
                name: '',
                path: ''
            },
            list: {
              postTypeName: "",
              workTypeName: "",
            },
            fullscreenLoading: true,
            mapConfig: {
                zoom: 15,    // 当前缩放层级
                zooms: [14, 18],    // 地图缩放层级范围
                center: [106.629997, 26.646999],    // 地图中心点
            },
            markers: [
                {
                    position: [106.629997, 26.646999]
                }
            ],
            plugin: [
                {
                    pName: 'Geolocation',
                    events: {
                        init(o) {
                            // o 是高德地图定位插件实例
                            // o.getCurrentPosition((status, result) => {
                            //     console.log('status:', status)
                            //     console.log('result:', result)
                            //     if (result && result.position) {
                            //         self.lng = result.position.lng;
                            //         self.lat = result.position.lat;
                            //         self.center = [self.lng, self.lat];
                            //         self.loaded = true;
                            //         self.$nextTick();
                            //     }
                            // });
                        }
                    }
                }
            ],
            userInfo: {},
            deleteDialogFormVisible: false,   // 删除按钮弹窗
            isDialogPublish: false
        };
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            // 面包屑跳转
            vm.breadcrumb.path = from.fullPath
            if (from.name) {
                sessionStorage.setItem('path', from.fullPath)
            }
        })
    },
    computed: {
        ...mapGetters(["identity"]),
        identityChange() {
            // return this.$store.state.mapbox.map;
            return this.identity;
        }
    },
    watch: {
        identityChange(newVal, oldVal) {
            this.getDictTypeAPI()
        }
    },
    mounted() {
        this.breadcrumb.name = this.$route.query.name

        this.userInfo = this.$store.state.user.userInfo
        // 刷新问题
        this.breadcrumb.path = sessionStorage.getItem('path') || ''
    },
    destroyed() {
        sessionStorage.removeItem('path')
    },
    created() {
        store.watch(() => store.state.user.identity, (newValue) => {
          if(newValue === '2' && this.breadcrumb.name === "我的报名") {
            this.breadcrumb.name = "我的岗位";
            this.breadcrumb.path = "/personalCenter/indexHr?name=我的岗位";
            return;
          }
          if(newValue === '1' && this.breadcrumb.name === "我的岗位") {
            this.breadcrumb.name = "我的报名";
            this.breadcrumb.path = "/personalCenter/index?name=我的报名";
          }
        });
        this.getDictTypeAPI()
    },
    methods: {
        getInit(arr) {
            let obj = {}
            // this.fullscreenLoading = true
            $api.getCompanyWorkWantInfoAPI({ id: this.$route.query.id, obj }).then(res => {
                let { code, msg, data } = res
                this.fullscreenLoading = false
                if (code == 200) {
                    this.list = JSON.parse(JSON.stringify(data))
                    // this.mapConfig.center = []
                    // this.mapConfig.markers[0].position = []
                    this.list.createTime = this.list.createTime ? parseTime(this.list.createTime, '{y}-{m}-{d} {h}:{i}:{s}') : ''
                    arr.map(item => {
                        if (item.dictType == 'crm_work_post_type' && this.list.postType == item.dictValue) {  // 岗位类别
                            this.list.postTypeName = item.dictLabel
                        }
                        if (item.dictType == 'crm_pro_unit' && this.list.proUnit == item.dictValue) {  // 岗位类别
                            this.list.proUnitName = item.dictLabel
                        }
                    })
                console.log('list:',this.list)
                } else {
                    this.fullscreenLoading = false
                }
            }).catch(error => {
              console.error(error)
                // this.$message.error(error.msg)
                this.fullscreenLoading = false
            })
        },
        getDictTypeAPI() {
            // this.fullscreenLoading = true
            // 培训报名 性别 岗位等字典 rm_train_user_type
            $api.dictTypeAPI({ dictType: 'crm_work_post_type,crm_pro_unit' }).then(res => {
                let { code, msg, data } = res
                if (code == 200) {
                    this.getInit(data)
                } else {
                    this.$message.error(msg)
                }
            }).catch(error => {
                this.fullscreenLoading = false
            })
        },
        // 查看报名列表
        async GomyPositionList() {
            let obj = {}
            let detail = await $api.getCompanyWorkWantInfoAPI({ id: this.$route.query.id, obj })
            if (detail.code == 200 && detail.data && detail.data.delFlag == 2) {
                return this.$message.warning('岗位已取消')
            }
            if(this.identity == 2) {   // hr
                this.$router.push({ path: '/personalCenter/indexHr', query: { name: '我的岗位', isDetail: 1, companyWorkwantId: this.list.id  } });
            } else {  // 工人
                this.$router.push({ path: '/personalCenter/index', query: { name: '我的岗位', isDetail: 1, companyWorkwantId: this.list.id  } });
            }
            // this.$router.push({ path: '/personalCenter/index', query: { name: '我的报名', companyWorkwantId: this.list.id } })
        },
        // 面试回放
        async reviewPlay() {
            let obj = {}
            let detail = await $api.getCompanyWorkWantInfoAPI({ id: this.$route.query.id, obj })
            if (detail.code == 200 && detail.data && detail.data.delFlag == 2) {
                return this.$message.warning('岗位已取消')
            }
            console.log('examType', this.list)
            let userInfo = this.$store.state.user.userInfo
            // this.$router.push({ path: '/personalCenter', query: { name: '历史面试', isDetail: 1, id: this.list.id , userId: userInfo.userId } })
            if(this.identity == 2) {   // hr
                this.$router.push(
                    { path: '/personalCenter/indexHr',
                     query: {
                        name: '历史面试' ,
                        name2: '面试回放' ,
                        isDetail: 1,
                        id: this.list.id ,
                        companyWorkwantId:  this.list.id,
                        applyCustomerId: userInfo.userId,
                        examType: this.list.examType
                    } });
            } else {  // 工人
                this.$router.push(
                    { path: '/personalCenter/index',
                     query: {
                        name: '历史面试' ,
                        name2: '面试回放' ,
                        isDetail: 1,
                        id: this.list.id ,
                        companyWorkwantId:  this.list.id,
                        applyCustomerId: userInfo.userId,
                        examType: this.list.examType
                } });
            }
        },
        // 开始面试
        async goToReview() {
            let obj = {}
            let detail = await $api.getCompanyWorkWantInfoAPI({ id: this.$route.query.id, obj })
            if (detail.code == 200 && detail.data && detail.data.delFlag == 2) {
                return this.$message.warning('岗位已取消')
            }
            $api.getUserJobWantAPI().then(res => {
                if (res.code == 200 && JSON.stringify(res.data) == "{}") { // 没有简历
                    this.$refs.resumeTipDialog.showDialog()
                } else {
                    this.$router.push({
                        path: '/personalCenter/jobDetailsAI',
                        query: {
                            name: this.breadcrumb.name,
                            id: this.list.id ,
                            companyWorkwantId: this.list.id,
                            path: this.breadcrumb.path
                        }
                    })
                }
            }).catch(error => {
                // this.$refs.resumeTipDialog.showDialog()
            })
        },
        // 报名登记
        async goToRegistration() {
            let obj = {}
            /*let detail = await $api.getCompanyWorkWantInfoAPI({ id: this.$route.query.id, obj })
            if (detail.code == 200 && detail.data && detail.data.delFlag == 2) {
                return this.$message.warning('岗位已取消')
            }*/
            $api.getJobWantApplyAPI({ companyWorkwantId: this.list.id }).then(res => {
                if(res.code == 200) {
                    if (!res.success) {   // 没有简历
                        this.$refs.resumeTipDialog.showDialog()
                    } else {
                        this.getDictTypeAPI()
                        this.$message.success('登记成功')
                    }
                }
            }).catch(error => {
                // this.$refs.resumeTipDialog.showDialog()
            })
        },
        // 联系老板
        async goConnection() {
            // 获取电话号码
            let item = this.list
            let obj = {}
            let detail = await $api.getCompanyWorkWantInfoAPI({ id: this.$route.query.id, obj })
            if (detail.code == 200 && detail.data && detail.data.delFlag == 2) {
                return this.$message.warning('岗位已取消')
            }
            $api.getPhoneBossAPI({id: item.id}).then(res=>{
                let {data, success} = res
                if(data && success) {
                    // 订单类型，1:招工电话,2:工人电话,3:抢单,4:充值,5:购买会员,6:充值钱包,7:ai大模型使用招工,8:ai大模型使用工匠,9:悬赏招工,10:企业会员权益
                    let obj = {
                        phone: data,
                        userName: item.employerContact,
                        avatar: item.avatar ? item.avatar :  item.sex == '男' ? this.workImg : this.hrImg
                    }
                    this.$refs.callDialog.showDialog(obj)
                } else {
                    let obj = {
                        id: item.id,
                        goodsId: item.id,
                        orderType: this.identity == 1 ? 1 : 2,    // 这个页面跳过去 写死
                        phone: data,
                        userName: item.employerContact,
                        avatar: item.avatar ? item.avatar :  item.sex == '男' ? this.workImg : this.hrImg,
                        sex: item.sex,
                        isBoss: true
                    }
                    this.$refs.scanCodePay.showDialog(obj)
                }
            })
        },
        deleteFn() {
            let obj = {}
            $api.getCompanyWorkWantInfoAPI({ id: this.$route.query.id, obj }).then(res => {
                let { code, msg, data } = res
                if (code == 200) {
                    if (data && data.delFlag == 2) {
                        this.$message.warning('岗位已取消')
                    } else {
                        let obj = {
                            id: this.list.id,
                            path: this.breadcrumb.path.split('?')[0],    // 直接传地址?name=''  拿到的名字乱码了
                            name: this.breadcrumb.name
                        }
                        this.$refs.deleteDialog.showDialog(obj)
                    }
                }
            })
        },
        // 发布岗位之后刷新
        flashData(data) {
            this.isDialogPublish = false
            if(data != '关闭') {
                this.getDictTypeAPI()
            }
            // this.$forceUpdate()
        },
        // 发布岗位
        publishDialog() {
            let obj = {}
            // this.fullscreenLoading = true
            $api.getCompanyWorkWantInfoAPI({ id: this.$route.query.id, obj }).then(res => {
                let { code, msg, data } = res
                if (code == 200) {
                    if (data && data.delFlag == 2) {
                        this.$message.warning('岗位已取消')
                    } else {
                        this.isDialogPublish = true  // 地图的小icon层级过高
                        this.$refs.commonForm.showCommonForm(this.list,'编辑岗位')
                    }
                }
            })
            // this.dialogFormVisible = true

        },
      textToListByBreak(text) {
        return text.replace("\r\n", "\n").replace("\r", "\n").split('\n').filter(t => t.length > 0);
      },

    },
};
</script>

<style lang="scss" scoped>
.flexCenter {
    display: flex;
    justify-content: start;
    align-items: center;
}
.bg {
    height: 100%;
    width: 100%;
    background: url('@/assets/intelligentInterview/detailBg.png');
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    .el-breadcrumb {
        height: 64px;
        line-height: 64px;
    }
    overflow: hidden;
}

// 左侧
.detail-container {
    width: 1260px;
    background: #FFFFFF;
    border-radius: 12px;

    padding: 28px 21px 0;
    box-sizing: border-box;
    .top-row {
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        padding: 0 21px;
        display: flex;
        justify-content: space-between;
        position: relative;
        .left-info {
            min-width: 560px;
        }
        .deleteBtn {
            display: flex;
            position: absolute;
            right: 20px;
            span {
                margin-left: 18px;
                padding: 0 28px;
                height: 42px;
                line-height: 42px;
                border-radius: 8px;
                border: 1px solid #E42525;
                font-size: 16px;
                color: #E42525;
                cursor: pointer;

            }
            .btn6ff {
                color: #3076FF;
                border-color: #3076FF;
                background: #fff;
            }
            .btnbe {
                background: #BEBEBE;
                border-color: #BEBEBE;
                color: #fff;
            }
            .btnBg6ff {
                color: #fff;
                border-color: #3076FF;
                background: #3076FF;
            }
            .btn33 {
                color: rgba(151, 151, 151, 0.2);
                border: 1px solid rgba(151, 151, 151, 0.2);
                background: #fff;
            }

            .edit {
                color: #3076FF;
                border-color: #3076FF;
            }
        }

        .name {
            max-width: 620px;
            font-weight: 500;
            font-size: 22px;
            color: #333333;
            margin-right: 20px;
        }

        .money {
            font-weight: 500;
            font-size: 22px;
            color: #1A67E2;
        }

        .tag {
            margin-right: 10px;
            padding: 0 8px;
            height: 26px;
            line-height: 26px;
            background: #3076FF;
            border-radius: 0px 13px 13px 0px;
            color: #fff;
        }

        .info {
            margin-top: 13px;
            padding-bottom: 11px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            letter-spacing: 1px;

            span {
                // margin-right: 60px;
            }
        }
    }

    .line {
        height: 1px;
        border-top: 1px solid rgba(151, 151, 151, 0.2);
    }

    // 已报名人数
    .personNumber {
        padding: 26px 21px 28px;
        @extend .flexBetween;
        font-weight: 500;
        font-size: 18px;
        color: #333333;

        .btn {
            height: 42px;
            line-height: 42px;
            background: #52C4A4;
            border-radius: 21px;
            padding: 0 25px;
            font-weight: 400;
            font-size: 16px;
            color: #FFFFFF;
            cursor: pointer;
        }
    }
    .btnBox {
        .btn {
            display: inline-block;
            height: 42px;
            line-height: 42px;
            background: #52C4A4;
            border-radius: 21px;
            padding: 0 25px;
            font-weight: 400;
            font-size: 16px;
            color: #FFFFFF;
            cursor: pointer;
        }
        .btn33 {
            color: rgba(151, 151, 151, );
            border: 1px solid rgba(151, 151, 151,);
            background: #fff;
        }
    }

    // 岗位职责 工作内容等信息
    .detail {
        // padding: 21px 21px;
        padding: 21px 21px 10px;
        padding-left: 21px;

        .tag {
            display: flex;
            margin-bottom: 21px;

            span {
                margin-right: 6px;
                height: 26px;
                line-height: 26px;
                padding: 0 8px;
                border-radius: 2px;
                color: #fff;
                font-size: 14px;
                background: #52C4A4;

                &:first-child {
                    background: #0B2467;
                }
            }
        }

        .name {
            margin-bottom: 7px;
            font-weight: 500;
            font-size: 18px;
            color: #333333;
            line-height: 25px;
        }

        .info {
            //width: 85%;
            width: 62%;
            font-size: 16px;
            color: #606060;
            line-height: 24px;
            word-wrap: break-word;
            overflow-wrap: break-word;
        }

        .mb15 {
            margin-bottom: 15px;
        }

        // 工作地址
        .map-wrap {
            border: 1px solid #DEDEDE;
            border-radius: 10px;
            width: 734px;

            .adress {
                padding-left: 13px;
                height: 48px;
                line-height: 48px;
                border-bottom: 1px solid #DEDEDE;
                font-size: 16px;
                color: #606060;
            }

            .map {
                height: 321px;
                overflow: hidden;
            }
        }

    }



    .flex {
        display: flex;
    }

    .flexBetween {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .flexEnd {
        display: flex;
        justify-content: flex-end;
    }

    * {
        box-sizing: border-box;
    }

    .mb40 {
        margin-bottom: 40px;
    }

}

// 视频面试
.ritght-content {
    width: 40%;
    margin-top: 40px;
    margin-left: 40px;
}
.disableBtn {
    cursor: not-allowed!important;
}

::v-deep.map {
    border-radius: 0 0 10px 10px;
}


</style>
