<template>
  <div style="width: 100%; height: 100%">
    <div v-if="!startView">
      <div class="video-content">
        <video ref="backgroundVideo" :src="videoSrc" loop muted playsinline></video>
      </div>

      <!-- 模拟面试按钮 -->
      <div class="bottom-btnBox">
        <div
            class="bottom-btn"
            @click="startRecording"
        >
          <img
              class="bgImg"
              src="@/assets/intelligentInterview/bottomBtn.png"
              alt=""
          />
          <span>{{ companyWorkWantId && jobInfo.isCurrentUserInterview == '1' ? '已面试' : ( companyWorkWantId ? '开始面试' : '开始模拟面试') }}</span>
        </div>
      </div>
    </div>
    <div v-else style="width: 100%; height: 100%">
      <centerConNew :default-question="defaultQuestion" :work-type-id="workTypeId" :user-jobwant-id="userJobwantId"
                    :company-work-want-id="companyWorkWantId" ref="centerConNewRef" @endInterview="endInterview" v-on="$listeners" />
    </div>
  </div>
</template>

<script>
import centerConNew from '@/views/aiInterview/components/centerConNew.vue'
import {getCompanyWorkWantInfoAPI} from "@/api/personalCenter";

export default {
  name: "centerInterview",
  components: {centerConNew},
  props: {
    //面试完成后是否需要回调告知父组件刷新列表
    needFlush: {
      type: Boolean,
      default: false,
    },
    defaultQuestion: {
      type: String,
      default: "人力资源",
    },
    workTypeId: {
      type: String,
      default: "",
    },
    userJobwantId: {
      type: String,
      default: "",
    },
    companyWorkWantId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      startView: false, // 开始ai面试
      preventBack: false,
      videoSrc: require("@/assets/video/videoBack.mp4"),
      jobInfo: {}
    }
  },

  methods: {
    startRecording() {
      if (this.jobInfo.isCurrentUserInterview == '1' && this.companyWorkWantId) {
        return
      }
      this.startView = true
      this.$nextTick(() => {
        this.$refs.centerConNewRef.startInterview()
        this.$emit("startInterview")
      })
    },
    handlePopState(event) {
      if (this.preventBack) {
        event.preventDefault();
        alert('不允许返回！');
      }
    },
    endInterview() {
      this.startView = false
    },
    getJobDetail(id){
      getCompanyWorkWantInfoAPI({ id }).then(res => {
        let {code, msg, data} = res
        if (code == 200) {
          this.jobInfo = data
          console.log('请求结果====',data)
        }
      })
    }
  },

  mounted() {
    window.addEventListener('popstate', this.handlePopState);
  },

  beforeDestroy() {
    window.removeEventListener('popstate', this.handlePopState);
    if (this.$refs.centerConNewRef)
      this.$refs.centerConNewRef.aheadClose()
  }
}
</script>

<style scoped lang="scss">
  .video-content {
    display: flex;
    justify-content: center;
    overflow: hidden;

    video {
      //height: 26.05263rem;
      width: 26rem;
      animation-duration: 0.5s;
      /* 动画持续时间 */
      animation-timing-function: linear;
      /* 动画速度曲线 */
      animation-iteration-count: 1;
      /* 动画播放次数 */
      animation-fill-mode: forwards;
      /* 动画结束后的状态 */
    }
  }

.bottom-btnBox {
  text-align: center;
  height: 142px;
  border-radius: 12px;

  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  box-sizing: border-box;

  // 模拟面试按钮
  .bottom-btn {
    height: inherit;
    width: 100%;
    border-radius: 12px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      z-index: 99;
      font-size: 36px;
      font-weight: bold;
      transform: skewX(-15deg);
      cursor: pointer;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }

    .bgImg {
      width: 326px;
      height: 70px;
    }

    .textImg {
      width: 90px;
      height: 32px;
      top: 48%;
    }
  }

  // 关闭摄像头 结束面试  文字输入按钮
  .bottom-btning {
    height: 100%;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: space-around;

    .img-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.5s;
      // &:hover {
      //     transform: scale(1.1);
      // }

      img {
      }

      .imgBox {
        width: 75px;
        height: 75px;
        background: rgba(73, 73, 73, 0.45);
        border-radius: 19px;
        backdrop-filter: blur(13.16239316239316px);

        display: flex;
        // flex-direction: column;
        justify-content: center;
        align-items: center;
        // img {
        //     width: 50px;
        //     height: 42px;
        // }
        &.colorRed {
          background: rgba(238, 87, 83, 1);
        }
      }

      .text {
        margin-top: 14px;
        font-weight: 400;
        font-size: 15px;
        color: #333333;
        line-height: 21px;
      }
    }
  }

  // 文字输入框
  .bottom-input {
    border: 1px solid transparent;
    height: 100%;
    padding-left: 18px;
    border-radius: 12px;
    background: rgba(0, 0, 0, 0.1);
    // justify-content: space-around;
    .btnIcon {
      margin-top: 18px;
      width: max-content;
      padding: 10px 18px;
      border-radius: 24px;
      background: #2768d8;
      font-size: 16px;
      color: #fff;
      cursor: pointer;
    }

    .textareaBox {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 10px;
      width: 100%;
      height: 50px;
      padding-right: 18px;
      box-sizing: border-box;

      .text-video {
        width: 58px;
        height: 58px;
        cursor: pointer;
        margin-top: 10px;
        margin-left: 6px;
      }

      .no-click {
        pointer-events: none; /* 这将使元素不响应点击事件 */
        cursor: no-drop;
      }

      ::v-deep .el-textarea {
        width: 100%;
        height: 100%;

        .el-textarea__inner {
          line-height: 20px;
          height: 100%;
          padding: 10px 10px;
          font-size: 16px;
          border-radius: 8px;

          &::placeholder {
            color: #555;
            font-size: 14px;
          }
        }
      }
    }

    .el-textarea__inner {
      padding: 17px 17px;
    }
  }

  .recordVideoDialog {
    position: fixed;
    // box-sizing: border-box;
    // left: 50%;
    z-index: 9999999;
    // top: -350%;
    width: 100%;
    height: 100%;
    transform: translateY(calc(-67vh));
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10vh;
    // flex-direction: column;
    transition: opacity 1s ease, visibility 1s;
    opacity: 0;
    visibility: hidden;

    .dialog-content {
      z-index: 999;
      width: 474px;
      height: 270px;
      background: linear-gradient(180deg, #1d2136 0%, #3e5466 100%);
      box-shadow: inset 0px -12px 56px 0px rgba(0, 0, 0, 0.4);
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .title {
        margin-bottom: 10px;
        font-size: 18px;
        color: #9af6ff;
        line-height: 25px;
        font-weight: 550;
      }

      & img {
        margin-top: 5px;
        width: 135px;
        height: 60px;
      }

      .tip {
        margin-top: 10px;
        font-size: 16px;
        color: #ffffff;
        line-height: 22px;
      }

      .btn-row {
        display: flex;
        flex-direction: row;
        margin-top: 15px;

        .cancel {
          text-align: center;
          width: auto;
          height: 46px;
          border-radius: 4px;
          border: 1px solid #ffffff;
          color: #ffffff;
          font-size: 16px;
          background: linear-gradient(180deg, #1d2136 0%, #3e5466 100%);
          padding: 8px 24px;
        }

        .sumbit {
          margin-left: 15px;
          text-align: center;
          width: auto;
          height: 46px;
          background-color: #ffffff;
          border-radius: 4px;
          color: #333333;
          font-size: 16px;
          padding: 8px 24px;
        }
      }
    }
  }

  .recordVideoDialog-show {
    opacity: 1;
    visibility: visible;
  }
}
</style>
