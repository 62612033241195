<template>
    <div v-if="start">
        <div class="test-card" v-if="empEvalQuestionList.length > 0 && Number(currentIndex ) < Number(empEvalQuestionList.length) && !showSucess">
          <div class="previous-btn">
            <el-button @click="previous">上一题</el-button>
          </div>
          <div>请勾选最能形容你的句子</div>
            <!-- 题目 -->
            <div class="topic">{{empEvalQuestionList[currentIndex].quDesc}}</div>

            <!-- 选项 -->
            <div class="btn-wrap">
                <!-- <el-button
                    :class="index == answerIndex ? 'btnBule' : ''"
                    v-for="(item,index) in empEvalQuestionList[currentIndex].webEmpEvalQuOptionsList"
                    :key="index"
                    @click="selectAnswer(item, index, empEvalQuestionList[currentIndex])">{{item.optionContent}}</el-button> -->
                    <span
                        :id="'selectItem' + index"
                        :class="['btn', index == answerIndex ? 'btnBule' : '']"
                        v-for="(item,index) in empEvalQuestionList[currentIndex].webEmpEvalQuOptionsList"
                        :key="index"
                        @click="selectAnswer(item, index, empEvalQuestionList[currentIndex])">
                        {{item.optionContent}}
                    </span>
            </div>
        </div>
        <div class="test-card" v-if="showSucess">
            <img src="@/assets/myTest/sucess.png" alt="">
            <div class="tip">恭喜您，测试题已答完</div>
            <el-button class="btnBule" style="margin-top: 100px; width: 230px;" @click="GOToResult">查看结果</el-button>
        </div>
        <el-progress
            v-if="!showSucess"
            class="progress"
            :percentage="percentage"
            :stroke-width="12"
            :format="progressFormat"
            color="#3076FF" ></el-progress>

    </div>
    <div v-else class="test-card-know">
      <span class="test-card-know--title">人格测试须知</span>
      <div class="test-card-know--textArea">
        <span>1．请在心态平和及时间充足的情况下才开始答题。</span>
        <span>2．请注意，题目的答案无对错之分，你不需要考虑哪个答案“应该”更好，而且不要在任何问题上思考太久，而是应该凭你心里的第一反应做出选择。</span>
        <span>3．如果你觉得在不同的情境里，两个答案或许都能反映你的倾向，请选择一个对于你的行为方式来说最自然、最顺畅和最从容的答案。</span>
      </div>
      <el-button type="primary" class="test-card-know--btn" @click="start = true">开始答题</el-button>
    </div>
</template>

<script>
export default {
    name: 'test-card',
    components: {},
    data() {
        return {
            start: false,
            currentIndex: 0,   // 当前答题的索引
            empEvalQuestionList: [],
            answerIndex: null,
            answerList: [],
            animationIng: false,
            showSucess: false,    // 是否答题完成
            percentage: 0,
            interview: false, //是否是面试
        };
    },
    mounted() {
    },
    destroyed() {
        this.$store.commit('setAnswerList', [])
        this.$store.commit('setCurrentIndex', 0)
    },
    methods: {
        // 进度条显示 1/5
        progressFormat() {
            return this.currentIndex + '/' + this.empEvalQuestionList.length
        },
        progressIncrease() {
            this.percentage = Number(this.currentIndex ) < Number(this.empEvalQuestionList.length ) ? parseInt((this.currentIndex / (this.empEvalQuestionList.length)).toFixed(2) * 100)  : 100
        },
        // 获取数据
        getEmpEvalQuestionList(data) {
            this.empEvalQuestionList = data
            // 刷新  问题解决
            this.answerList = this.$store.state.myTest.answerList || []

            this.currentIndex = this.$store.state.myTest.currentIndex || 0
            this.percentage = Number(this.currentIndex ) < Number(this.empEvalQuestionList.length) ? parseInt((this.currentIndex / this.empEvalQuestionList.length).toFixed(2) * 100)  : 100
            // 刷新  问题解决  end
        },
      //previous
      previous(event) {
          let target = event.target;
          if (target.nodeName === "BUTTON" || target.nodeName === "SPAN"){
            target.parentNode.blur();
          }
          target.blur();
          if(this.currentIndex == 0){
            return
          }else {
            this.currentIndex--
            this.answerList.pop()
            this.progressIncrease()
          }
      },
        // 选的答案
        selectAnswer(row, index, parentRow) {
          if(this.animationIng){
            return
          }
            let obj = {}
            obj.userId = parentRow.id
            obj.examId = parentRow.examId
            obj.quNum = parentRow.quNum
            obj.quId = row.quId
            obj.optionId = row.id
            obj.optionScores = row.optionScores
            obj.optionCard = row.optionCard
            obj.mobile = this.$store.state.user.userInfo.phonenumber
            this.answerList.push(obj)
            this.answerIndex = index
            let a = document.getElementById('selectItem' + index)
            //添加样式类btn-load
            a.classList.add('btn-load')
            this.animationIng = true
            setTimeout(() => {
              this.currentIndex++
              a.classList.remove('btn-load')
              this.animationIng = false
              // this.$store.commit('setAnswerList', this.answerList)
              // this.$store.commit('setCurrentIndex', this.currentIndex)
              this.progressIncrease()
              if (Number(this.currentIndex) < Number(this.empEvalQuestionList.length )) {
                this.answerIndex = null   // 下一题 重置为0  不然 下一题会有选中效果
              } else {
                this.$emit('subExamAnswers', this.answerList )
              }
            },500)
         },
        GOToResult(row) {
            this.showSucess = false
            if (this.interview){
              if(this.$store.state.user.identity == 1){
                this.$router.push({
                  path: '/personalCenter/index',
                  query: {
                    name: '面试记录',
                  }
                })
              }else {
                this.$router.push({
                  path: '/personalCenter/indexHr',
                  query: {
                    name: '面试记录',
                  }
                })
              }
            }else {
              this.$router.push({
                path: '/personalCenter/index',
                query: {
                  name: '职业测评',
                  isDetail: 1,
                  id: this.$route.query.id,
                  examType: this.$route.query.examType,
                }
              })
            }
        },
    }
}
</script>
<style lang='scss' scoped>
.progress {
    display: flex;
}
.btn-load{
  //背景颜色变化的动画效果，只循环一次
  animation: btn-back-load 0.5s linear infinite;
}
@keyframes btn-back-load {
  100% {
    background-color: rgba(48, 118, 255, 0.85);
  }
}
.test-card-know{
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .test-card-know--title{
    font-weight: 500;
    font-size: 22px;
    color: #333333;
  }
  .test-card-know--textArea{
    margin-top: 24px;
    background: #F2F3F6;
    border-radius: 12px;
    padding: 34px;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 18px;
    color: #333333;
    line-height: 25px;
    span{
      margin-bottom: 10px;
    }
  }
  .test-card-know--btn{
    margin-top: 30px;
    padding: 12px 63px;
    font-size: 18px;
  }
}
.test-card {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #333333;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .previous-btn{
      position: absolute;
      left: 5px;
      top: 50px;
    }
    .topic {
        margin-top: 60px;
        margin-bottom: 44px;
        font-weight: 500;
        font-size: 22px;
        color: #333333;
    }
    .btn-wrap {
        display: flex;
        flex-direction: column;
        align-content: center;
        min-width: 230px;


        .el-button {
            margin-left: 0;
            margin-bottom: 25px;
            width: 100%;
        }
    }
    .btn {
        margin-left: 0;
        padding: 0 10px;
        margin-bottom: 25px;
        width: 100%;
        height: 50px;
        line-height: 50px;
        text-align: center;
        border-radius: 5px;
        border: 1px solid #999999;
        user-select: none;
        font-weight: 500;
        font-size: 18px;
        color: #333333;
        cursor: pointer;
        transition: all 0.5s;
        &:hover {
            // color: #fff;
            // background: #1A67E2;
            border-color: #1A67E2;
        }
    }
    .btnBule {
        // margin-left: 12px;
        // color: #fff;
        // background: #1A67E2;
        border-color: #1A67E2;
    }

    .tip {
        font-weight: 500;
        font-size: 22px;
        color: #333333;
        margin-top: 26px;
    }
    img {
        width: 134px;
        height: 134px;
    }
}
</style>
