<template>
  <div class="post-container">
    <div class="tip-container">
      <div class="tip-card" v-for="(tip, index) in processedTipsList" :key="index">
        <el-tooltip class="item" effect="dark" :content="tip.tooltipContent" placement="top"
          :disabled="tip.tooltipDisabled">
          <span class="tip" @click="pushInput(tip.modelSearch)">{{
            tip.displayText
          }}</span>
        </el-tooltip>
      </div>
    </div>
    <img src="@/assets/common/video-icon.png" alt="录音" style="" @click="recordVideo" class="video-icon"
      :class="{ 'no-drop': isDoing }" />
    <!--    <textarea-->
    <!--        id="textInput"-->
    <!--        type="text"-->
    <!--        v-model="userInput"-->
    <!--        placeholder="输入您的问题，按下回车键发送"-->
    <!--        maxlength="2000"-->
    <!--        class="input-field"-->
    <!--        @keypress.enter.prevent="sendMessage"-->
    <!--        :disabled="isDoing"-->
    <!--        :class="{ 'no-drop': isDoing }"-->
    <!--    ></textarea>-->
    <el-input id="textInput" v-model="userInput" placeholder="请输入您想找的岗位名称，按下回车键发送" maxlength="200" class="input-field"
      @keyup.enter.native="sendMessage" :disabled="isDoing" :class="{ 'no-drop': isDoing }" show-word-limit
      type="textarea"></el-input>
    <div style="cursor: pointer" @click="sendMessage">
      <span class="post-btn">发送</span>
    </div>
    <div class="recordVideoDialog" v-show="recordVideoDialog" :class="{ 'recordVideoDialog-show': recordVideoDialog }">
      <div class="dialog-content">
        <!-- 弹窗内容 -->
        <span class="title">语音输入中</span>
        <img src="../../assets/common/video-recording.gif" alt="" />
        <span class="tip">{{ videoContent }}</span>
        <div class="btn-row">
          <button class="cancel" @click="closeVideoDialog">取消</button>
          <button class="sumbit" @click="clickStopVoiceTranslation">
            {{ videoTitle }} ({{ remainingTime }}秒)
          </button>
          <!-- <button class="btn1" @click="handlePlay">播放录音</button> -->
        </div>
      </div>
    </div>
    <video-background ref="videoUser" v-show="showVideo"></video-background>
  </div>
</template>

<script>
import Recorder from "js-audio-recorder";
import { inputTipsApi } from "@/api/com";
import { req } from "@/utils/aiChat/request";
import VideoBackground from "@/components/postMessage/videoBackground.vue";
import debounce from "lodash/debounce";

export default {
  name: "postMessage",
  components: {
    VideoBackground
  },
  props: {
    // ai是否还在打字（打字机是否完成）
    showVideo: {
      type: Boolean,
      default: false,
    },
    isDoing: {
      type: Boolean,
      default: false,
    },
    sessionBatch: {
      type: String,
      default: "",
    },
    serviceType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      userInput: "",
      //   tips: ["电话面试需要注意的问题", "电话面试", "需要注意的问题"],
      tips: [],
      recordVideoDialog: false,
      recorder: null,
      timer: null,
      videoContent: "试试这样说：我想找个水电工的工作",
      videoTitle: "结束并提交",
      remainingTime: null,
    };
  },
  watch: {
    userInput(newValue, oldValue) {
      if (newValue) {
        this.getSelectTips();
      } else {
        this.tips = [];
      }
    },
  },
  computed: {
    processedTipsList() {
      return this.tips.map((item) => {
        const processedText = this.truncateText(item.modelSearch, 12);
        return {
          ...item,
          displayText: processedText.text,
          tooltipContent: processedText.tooltip ? item.modelSearch : "",
          tooltipDisabled: !processedText.tooltip, // 禁用工具提示，除非文本被截断
        };
      });
    },
  },
  methods: {
    //限制字数，用...替代
    truncateText(text, maxLength) {
      if (!text || typeof text !== "string") {
        // 检查text是否存在且为字符串
        return { text: "", tooltip: false };
      }
      if (text.length > maxLength) {
        return { text: text.substring(0, maxLength) + "...", tooltip: true };
      }
      return { text: text, tooltip: false };
    },
    clearPlay() {
      this.$refs.videoUser.clearPlay()
    },
    startPlay(audioUrl) {
      this.$refs.videoUser.playStart(audioUrl)
    },
    getSelectTips() {
      const sType = this.serviceType;
      const data = {
        serviceType: sType,
        modelSearch: this.userInput,
      };

      inputTipsApi(data)
        .then((res) => {
          this.tips = res.data.rows.slice(0, 4);
        })
        .catch((error) => {
        });
    },
    // 用户发送消息
    sendMessage() {
      this.$emit("update:isDoing", true);
      if (this.userInput.trim() !== "") {
        const messageData = {
          message: this.userInput,
          sessionBatch: this.sessionBatch, // 包含 sessionBatch，可能为 null
        };
        // 传递给父组件
        this.$emit("message-sent", messageData);
        // this.$emit("message-sent", this.userInput);
        // 清空输入框
        this.userInput = "";
      } else {
        this.$emit("update:isDoing", false);
      }
    },
    pushInput(tip) {
      this.userInput = tip;
      this.focusInput(); // 调用聚焦方法
    },
    focusInput() {
      // 使用 nextTick 确保 DOM 已更新
      this.$nextTick(() => {
        const input = document.getElementById("textInput");
        input.focus();
      });
    },
    recordVideo() {
      if (this.isDoing) {
        return;
      }
      Recorder.getPermission().then(
        () => {
          this.recordVideoDialog = true;
          this.initRecorder();
        },
        (error) => {
          this.$message({
            message: "请先允许该网页使用麦克风",
            type: "error",
          });
          console.log(`${error.name} : ${error.message}`);
          return;
        }
      );
    },
    closeVideoDialog() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.recorder?.stop();
      this.handleDestroy();
      this.recordVideoDialog = false;
    },
    initRecorder() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.recorder = new Recorder({
        sampleBits: 16, // 采样位数，支持 8 或 16，默认是 16
        sampleRate: 16000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，Chrome 是 48000
        numChannels: 1, // 声道数，支持 1 或 2， 默认是 1
      });
      this.recorder.start(); // 开始录音
      this.remainingTime = 30;

      this.timer = setInterval(() => {
        this.remainingTime--;
        if (this.remainingTime <= 0) {
          clearInterval(this.timer);
          this.timer = null;
          this.stopVideo();
          this.videoContent = "语音已到30s，请提交";
          this.videoTitle = "提交";
        }
      }, 1000);
    },
    //点击停止语音翻译
    clickStopVoiceTranslation: debounce(function () {
      if (this.remainingTime > 1) {
        clearInterval(this.timer);
        this.timer = null;
        this.stopVideo();
      }
    }, 1000),

    stopVideo() {
      clearInterval(this.timer);
      this.timer = null;
      this.recorder.stop();
      const formData = new FormData();
      const blob = this.recorder.getWAVBlob(); // 获取 pcm 格式音频数据
      // 此处获取到 blob 对象后需要设置 fileName 满足项目上传需求，这里选择直接把 blob 作为 file 塞入 formData
      const fileOfBlob = new File([blob], new Date().getTime() + ".wav");
      formData.append("file", fileOfBlob);
      //通过生成的临时URL将音频文件下载下来
      req("post", "/qps/tools/audio2text", formData).then((res) => {
        console.log('11111')
        if (res.data) {
          this.userInput = res.data;
          this.$emit("update:isDoing", true);
          if (this.userInput.trim() !== "") {
            const messageData = {
              message: this.userInput,
              sessionBatch: this.sessionBatch, // 包含 sessionBatch，可能为 null
            };
            // 传递给父组件
            this.$emit("message-sent", messageData);
            // this.$emit("message-sent", this.userInput);
            // 清空输入框
            this.userInput = "";
            this.$message({
              message: "语音发送成功",
              type: "success",
            });
          }

          if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
          }
          this.handleDestroy();

          //关闭弹窗
          this.recordVideoDialog = false;
        } else {
          this.$message({
            message: "语音并未识别到您说话",
            type: "warning",
            customClass: 'zZindex'
          });
          //关闭弹窗
          this.recordVideoDialog = false;
          if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
          }
        }
      });
    },
    handlePlay() {
      this.recorder.play(); // 播放录音
    },
    //销毁实例
    handleDestroy() {
      this.recorder = null;
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
};
</script>

<style lang="scss" scoped>
.post-container {
  background: #ffffff;
  border-radius: 6px;
  height: 81px;
  width: 1200px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  position: relative;
  margin-top: 55px;

  .tip-container {
    top: -42px;
    left: 0;
    position: absolute;
    display: flex;
    flex-direction: row;
    width: 1100px;

    .tip-card {
      background-color: #ffffff;
      border-radius: 18px;
      width: auto;
      height: 20px;
      padding: 8px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      cursor: pointer;

      .tip {
        font-weight: 400;
        font-size: 12px;
        color: #333333;
        line-height: 17px;
        text-align: left;
        font-style: normal;
      }
    }
  }


  .video-icon {
    width: 58px;
    height: 64px;
    cursor: pointer;
    margin-top: 10px;
  }

  .post-btn {
    height: 34px;
    background: #3076ff;
    border-radius: 6px;
    padding: 10px 16px;
    color: #ffffff;
    font-size: 14px;
  }

  .input-field {
    max-width: 1040px;
    height: 60px;
    flex-grow: 1;
    margin-right: 10px;
    padding: 0 8px;
    font-weight: 400;
    font-size: 14px;
    border-style: none;
    outline: none;
    resize: none;
    /* 防止用户调整大小 */
    overflow-y: auto;
    /* 允许垂直滚动 */
  }

  .no-drop {
    cursor: no-drop;
  }

  .input-field::placeholder {
    font-weight: 400;
    font-size: 14px;
    color: #969696;
    line-height: 20px;
    text-align: left;
    font-style: normal;
  }

  .send-button {
    padding: 8px 16px;
    background-color: #3076ff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .send-button:hover {
    background-color: #2056c2;
    /* 鼠标悬停时按钮的颜色变化 */
  }

  .recordVideoDialog {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // flex-direction: column;
    transition: opacity 1s ease, visibility 1s;
    opacity: 0;
    visibility: hidden;

    .dialog-content {
      width: 474px;
      height: 270px;
      background: linear-gradient(180deg, #1d2136 0%, #3e5466 100%);
      box-shadow: inset 0px -12px 56px 0px rgba(0, 0, 0, 0.4);
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .title {
        margin-bottom: 10px;
        font-size: 18px;
        color: #9af6ff;
        line-height: 25px;
        font-weight: 550;
      }

      & img {
        margin-top: 5px;
        width: 135px;
        height: 60px;
      }

      .tip {
        margin-top: 10px;
        font-size: 16px;
        color: #ffffff;
        line-height: 22px;
      }

      .btn-row {
        display: flex;
        flex-direction: row;
        margin-top: 15px;

        .cancel {
          text-align: center;
          width: auto;
          height: 46px;
          border-radius: 4px;
          border: 1px solid #ffffff;
          color: #ffffff;
          font-size: 16px;
          background: linear-gradient(180deg, #1d2136 0%, #3e5466 100%);
          padding: 8px 24px;
        }

        .sumbit {
          margin-left: 15px;
          text-align: center;
          width: auto;
          height: 46px;
          background-color: #ffffff;
          border-radius: 4px;
          color: #333333;
          font-size: 16px;
          padding: 8px 24px;
        }
      }
    }
  }

  .recordVideoDialog-show {
    opacity: 1;
    visibility: visible;
  }
}
</style>
