<template>
	<div>
		<div class="myRegistration-container">
			<div class="title">我的报名</div>
			<common-card v-if="list.length" :list="list" :isShowBtn="false"
				@parentDetail="GoDetail(item)"></common-card>
			<div class="paginationBox">
				<el-pagination v-if="page.total > page.pageSize" @size-change="handleSizeChange"
					@current-change="handleCurrentChange" :current-page="page.currentPage" :page-size="page.pageSize"
					background layout="total,prev, pager, next" :total="page.total">
				</el-pagination>
			</div>
			<el-empty v-if="list.length == 0" description="暂无数据"></el-empty>
		</div>
	</div>
</template>

<script>
import commonCard from './myRegistration/commonCard'
import * as $api from '@/api/personalCenter'
export default {
	name: 'myRegistration',
	components: {
		commonCard
	},
	data() {
		return {
			list: [],
			fullscreenLoading: false,
			page: {
				pageSize: 12, // 每页条数
				pageNum: 1, // 当前页数
				total: 0, // 工作总记录数
			}, // 分页
		}
	},
	watch: {
		'page.pageNum': {
			handler() {
				// this.getMyRecruitment();
			}
		},
	},
	mounted() {
		this.getInit()
	},
	methods: {
		handleSizeChange(val) {
			this.page.pageSize = val
			this.getInit()
		},
		handleCurrentChange(val) {
			this.page.pageNum = val
			this.getInit()
		},
		getpagination() {
			document.getElementsByClassName("el-pagination__jump")[0].childNodes[0].nodeValue = "跳至";
			document.getElementsByClassName("el-pagination__total")[0].childNodes[0].nodeValue = `总共${this.total}条 每页显示`;
		},
		GoDetail(item) {
			// this.$router.push({ path: '/personalCenter/jobDetails', query: { name: '我的报名', companyWorkwantId: id} })
		},
		// jobWantApplyAPI()
		getInit(arr) {
			this.fullscreenLoading = true
			let id = this.$route.query.companyWorkwantId
			let obj = {
				pageSize: this.page.pageSize, // 每页条数
				pageNum: this.page.pageNum, // 当前页数
			}
			let api = id ? $api.jobWantApplyAPI({ companyWorkwantId: id, ...obj }) : $api.jobApplyAPI({ postType: 0, ...obj })
			api.then(res => {
				this.fullscreenLoading = false
				// console.log("报名数据", res);
				if (res.code == 200) {
					this.list = (res.data.rows || [])
						.filter(item => !!item.companyWorkWant)
						.map(item => item.companyWorkWant)
						.filter(item => !!item.workTypeName);
					this.page.total = res.data.total || 0
				} else {
					this.fullscreenLoading = false
				}
			}).catch(error => {
				this.fullscreenLoading = false
			})
		},
	},
}
</script>

<style scoped lang="scss">
.myRegistration-container {
	// min-height: 822px;
	min-height: 700px;
	position: relative;

	.title {
		font-weight: bold;
		font-size: 22px;
		color: #333333;
		margin-left: 19px;
		margin-bottom: 21px;
	}
}

.el-empty {
	min-height: 720px;
}

.paginationBox {
	position: absolute;
	bottom: -20px;
	width: 100%;
	text-align: right;

	::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: #0066FF;
	}
}
</style>
