<template>
    <div class="interviewRecord-container"  v-loading="loading" >
        <div class="title">面试记录</div>
        <div class="queryForm-Box">
            <el-input
                style="width: 356px; margin-right: 24px;"
                v-model="queryParams.keyWord"
                @keyup.enter.native = "getInit()"
                clearable
                placeholder="输入员工姓名/岗位/面试评分/性别"></el-input>
            <el-date-picker
                style="width: 360px"
                v-model="timer"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="changeTimer"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                clearable>
                </el-date-picker>
                <!-- format="yyyy-MM-dd hh:mm:ss" -->
        </div>
        <div class="main">
            <el-table
                :data="tableData"
                style="width: 100%"
                :header-cell-style="{ background:'#F9FBFE',color: '#333' }">
                <el-table-column prop="userName" label="面试人员" align="center" width="160" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <div class="avater-name ">
                            <el-image
                                class="avater"
                                :src="scope.row.avatar || imgAvatar"
                                :preview-src-list="[scope.row.avatar]">
                            </el-image>
                            <span class="overflowline1">{{scope.row.userName}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="sex" label="性别" align="center">
                    <template slot-scope="scope">
                        <span>{{  filterSex(scope.row.sex ) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="workTypeName" label="岗位" align="center" width="260" :show-overflow-tooltip="true"/>
                <el-table-column prop="videoUpLoad" label="视频上传状态" align="center" width="120">
                    <template slot-scope="scope">
                        <span>{{  scope.row.videoUpLoad == 1 ? '已上传' : '未上传' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="interviewAppraise" label="面试评分" align="center"/>
                <el-table-column prop="isExam" label="岗位是否需要测评" align="center" width="160">
                    <template slot-scope="scope">
                        <span>{{  scope.row.isExam == 1 ? '是' : '否' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="willInterestWork" label="职业测评" align="center" width="260" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <div class="column-tag ">
                            <div class="overflowline1 " v-if="scope.row.examName" >
                              {{scope.row.examName}} <span style="font-size: 18px; padding: 0 5px">-</span> {{ scope.row.examResult}}

                            </div> 
                            <span v-if="!scope.row.examName" style="color: #E7303E">未测试</span>
                            <div class="overflowline1">
                                <span class="tag-item">{{ scope.row.willInterestWork }}</span>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="interviewTime" label="面试时间" align="center" width="160"/>
                <el-table-column prop="date" label="面试回放" align="center" width="260">
                    <template slot-scope="scope">
                        <el-button type="text" style="color: #1A67E2;" @click="GoToDetail(scope.row)">点击查看</el-button>
                        <el-button type="text" style="color: #1A67E2;" @click="seeResume(scope.row)">简历</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="paginationBox">
                <el-pagination
                    v-if="page.total > queryParams.pageSize"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-size="queryParams.pageSize"
                    layout="total, prev, pager, next"
                    :total="page.total">
                </el-pagination>
            </div>
        </div>
        <!-- 简历 -->
        <ResumeTemplate ref="ResumeTemplate" />
    </div>
</template>

<script>
import * as $api from '@/api/personalCenter'
export default {
    name: 'interviewRecord',
    components: {},
    data() {
        return {
            tableData: [],
            page: {
                currentPage: 1,
                total: 0, // 工作总记录数
            }, // 分页
            queryParams: {
                keyWord: '',
                startDate: '',
                endDate: '',
                pageSize: 6, // 每页条数
                pageNum: 1, // 当前页数
            },
            timer: [],
            loading: true,
            sexList: [],
            userInfo: {},
            imgAvatar: null,
        };
    },
    created() {
        this.getDictTypeData()
        this.userInfo = this.$store.state.user.userInfo
        console.log('userInfo', this.userInfo)
        this.imgAvatar =  this.userInfo.avatar ? this.userInfo.avatar : (this.userInfo.identity == 2 ? require('@/assets/intelligentInterview/hr.png') : require('@/assets/intelligentInterview/work.png'))
    },
    filters: {
        // filterSex(val) {
        //     console.log('sex', this.sexList)
        //     let obj = this.sexList.find(item => item.dictValue == val)
        //     return obj ? obj.dictLabel : ''
        // }
    },
    methods: {
        filterSex(val) {
            let obj = this.sexList.find(item => item.dictValue == val)
            return obj ? obj.dictLabel : ''
        },
        changeTimer(val) {
            if (val) {
                this.queryParams.startDate = val[0]
                this.queryParams.endDate = val[1]
            } else {
                this.queryParams.startDate = ''
                this.queryParams.endDate = ''
            }
            this.getInit()
        },
        GoToDetail(item) {
            console.log('item', item)
          if(this.$store.state.user.identity == 1){
            this.$router.push({
              path: '/personalCenter/index',
              query: {
                name: '面试记录',
                isDetail: 1,
                id: item.id,
                examType: item.examType,
                userJonWantId: item.userJonWantId
              }
            })
          }else {
            this.$router.push({
              path: '/personalCenter/indexHr',
              query: {
                name: '面试记录',
                isDetail: 1,
                id: item.id,
                examType: item.examType,
                userJonWantId: item.userJonWantId
              }
            })
          }
        },
        // 查看简历
        seeResume(item) {
            this.$nextTick(() => {  // userJonWantId   传参格式 obj = { userJonWantId : xxxx }
                let obj = {
                    userJonWantId: item.userJonWantId
                }
                this.$refs.ResumeTemplate.getDetail(obj)
            })
        },
        getInit() {
            this.loading = true
            $api.getInterviewV1API(this.queryParams).then(res => {
                let { code, msg, data } = res
                this.loading = false
                if (code == 200) {
                    this.tableData = data.rows || []
                    this.page.total = data.total || 0
                    console.log('tableData', this.tableData)
                } else {
                    // this.$message.error(msg)
                }
            }).catch(error => {
                this.loading = false
                // this.$message.error(error.msg)
            })
        },
        handleSizeChange(val) {
            this.queryParams.pageSize = val
            this.getInit()
        },
        handleCurrentChange(val) {
            this.queryParams.pageNum = val
            this.getInit()
        },
        getDictTypeData() {
            $api.dictTypeAPI({ dictType: 'sys_user_sex' }).then(res => {
                let { code, msg, data } = res
                if (code == 200) {
                    this.sexList = data
                    this.getInit()
                }
            })
        },
    }
}
</script>
<style lang='scss' scoped>
::v-deep.interviewRecord-container {
    min-height: 822px;
    width: 100%;
    position: relative;
    .queryForm-Box {
        margin-top: 24px;
        display: flex;
        .el-input__inner {
            height: 42px;
            // border-radius: 4px;
            &::placeholder {
                color: #555;
            }
        }
        .el-date-editor {
            height: 42px;
            line-height: 42px;
            .el-range-input {
                &::placeholder {
                    color: #555;
                }
            }
            .el-range-separator {
                height: 42px;
                line-height: 42px;
            }
            .el-range__icon {
                color: #555;
            }
        }
        .el-range-editor {
            height: 42px;
            line-height: 42px;
            .el-input__inner {
                padding: 0!important;
            }
        }

    }
    .main {
        margin-top: 22px;
        .avater-name {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 160px;
            .avater {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                margin-right: 10px;
            }
        }
        .el-table {
            .column-tag {
                padding: 12px;
                background: #EAF5EA;
                border-radius: 4px;
                border: 1px solid #52C4A4;

                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #333333;
                .tag-item {
                    line-height: 22px;
                    &::after {
                        content: '|';
                        display: inline-block;
                        font-size: 12px;
                        margin: 5px;
                    }
                    &:last-child {
                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }

    }
    .paginationBox {
        // margin-top: 20px;
        text-align: right;
        position: absolute;
        bottom: 0;
        width: 100%;
        .el-pagination {
            .el-pager{
                // height: 42px;
                li.active {
                    background: rgba(46,105,245,0.1);
                    border-radius: 3px;
                }
            }
        }

    }
    .flexCenter {
        //display: flex;
        //align-items: center;
        //flex-wrap: wrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

    }
}
.overflowline1 {
    overflow: hidden; /*自动隐藏文字*/
    text-overflow: ellipsis;/*文字隐藏后添加省略号*/
    white-space: nowrap;/*强制不换行*/
    // width: 160px;/*不允许出现半汉字截断*/
}
</style>
